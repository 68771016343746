@import "./../../../styles/sass-resources";

ul.predictions {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateY(100%);
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: #fff;
  border-top: 2px solid $pondr-green;

  li{
      padding: .5rem;
      color: #999;
      cursor: pointer;
      text-align: left;

      &.selected{
          background-color: rgba(223, 223, 223, 0.801);
          color: #000;
      }

      &:hover{
          background-color: rgba(223, 223, 223, 0.801);
          color: #000;
      }
  }
}