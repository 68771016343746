@import "./../../../styles/sass-resources";

.wrapper {
    -webkit-overflow-scrolling: touch;

    &:not(.hasBeenActive) > g {
        transform-origin: center;
        animation: Grow;
        animation-duration: 0.3s;
        animation-fill-mode: forwards;
    }
}

.isCloudy {
    text {
        opacity: 0.2 !important;
    }
}

.isSoftDeleted {
    text {
        opacity: 0.3;
    }
}

.isDefault {
    text {
        transition: opacity 0.3s linear;
    }
}

.isReveal {
    pointer-events: none;
}

.addWordWrapper {
    .addWordBg {
        stroke-width: 1px;
        stroke: $pondr-green;
        stroke-radius: 4px;
        fill: $pondr-green;
    }

    .addWordText {
        fill: #fff;
    }
}

@keyframes Grow {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
