.Toastify__toast {
    &--default {
        color: #fff;
        background-color: $pondr-green;

        .Toastify__close-button {
            color: #fff;
        }
    }
}

.Toastify__toast-container--top-right {
    top: 5em;
}
