.wrapper {
    cursor: pointer;
    transition: opacity 0.3s linear;
}

.softDeleted {
    text {
        opacity: 0.2;
    }
}

.modeGiveMe {
    cursor: inherit;
}

.isMain {
    cursor: move;
}

.modeMark {
    cursor: url("/assets/images/cursor_mark.png") 10 20, auto;
}

.modeDelete {
    cursor: url("/assets/images/cursor_delete.png") 10 20, auto;
}
