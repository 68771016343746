@font-face {
    font-family: "symbols_lucida";
    src: url("../../../assets/fonts/symbols_lucida.eot?nxt5pz");
    src: url("../../../assets/fonts/symbols_lucida.eot?nxt5pz#iefix") format("embedded-opentype"),
        url("../../../assets/fonts/symbols_lucida.ttf?nxt5pz") format("truetype"),
        url("../../../assets/fonts/symbols_lucida.woff?nxt5pz") format("woff"),
        url("../../../assets/fonts/symbols_lucida.svg?nxt5pz#symbols_lucida") format("svg");
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "symbols_lucida" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-hand:before {
    content: "\e90e";
}
.icon-l_broom:before {
    content: "\e909";
}
.icon-l_crosshair:before {
    content: "\e90a";
}
.icon-l_exit_fullscreen:before {
    content: "\e90b";
}
.icon-l_fullscreen:before {
    content: "\e90c";
}
.icon-l_question:before {
    content: "\e90d";
}
.icon-l_options:before {
    content: "\e908";
}
.icon-arrow-left:before {
    content: "\e905";
}
.icon-arrow-right:before {
    content: "\e906";
}
.icon-add-cloud:before {
    content: "\e907";
}
.icon-erase:before {
    content: "\e900";
}
.icon-mark:before {
    content: "\e901";
}
.icon-undo:before {
    content: "\e902";
}
.icon-ok:before {
    content: "\e903";
}
.icon-not-ok:before {
    content: "\e904";
}
