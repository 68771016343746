.wrapper,
html,
body,
:global(#root) {
    width: 100%;
    height: 100%;
    min-height: 100%;
}

html {
    overflow: hidden;
}

body {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

:global(.dg.main.a) {
    float: left;
    margin-left: 5px;
}

text,
span,
div {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -o-user-select: none;
}

input {
    -webkit-user-select: text;
    -moz-user-select: text;
    user-select: text;
    -o-user-select: text;
}

:global(#canvas-container) {
    position: fixed;
    top: 0;
    bottom: 0;
    pointer-events: none;
    opacity: 0.4;
}

input {
    text-align: left !important;
}
