@import "./../../../styles/sass-resources";

.wrapper {
    position: relative;
    height: calc(100% - #{$workspace-bar-height});
    overflow: hidden;
}

.action {
    position: absolute;
    top: 10px;
    right: 15px;

    i {
        font-size: 40px;
    }
}

.container {
    height: calc(100% - 40px);
    overflow-y: scroll;

    margin-top: 20px;

    h1,
    h2 {
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 1px;
    }

    h1 {
        font-size: 25px;
    }

    h2 {
        font-size: 22px;
        &:first-child {
            margin-top: 0;
        }
    }

    h3 {
        font-size: 16px;
    }

    p {
        font-size: 14px;
        letter-spacing: 0.4px;
        strong {
            font-weight: 500;
        }
    }

    a {
        color: #000;
        font-weight: 500;
    }

    hr {
        border-top-color: #000;
    }
}
