/* ==========================================================================
   MIXINS
   Description: Usefull function / code snippets
   ========================================================================== */

a.parent-click {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0px;
    text-decoration: none;
    z-index: 10;
    overflow: hidden;
    background-color: white;
    opacity: 0;
    filter: alpha(opacity=0);
}

@mixin image-replacement($url, $width, $height) {
    display: block;
    width: $width;
    height: $height;
    background: url($url) no-repeat left top;
    background-size: $width $height;
    @include text-hide();
}

@mixin placeholder {
    &::-webkit-input-placeholder {@content}
    &:-moz-placeholder           {@content}
    &::-moz-placeholder          {@content}
    &:-ms-input-placeholder      {@content}
}

@function em($px, $base: $font-size-base) {
    @return ($px / $base) * 1em;
}

.btn.btn-ghost {

    &.btn-default {
        background-color: rgba($btn-default-bg, 0.3);
    }

    &.btn-primary {
        background-color: rgba($btn-primary-bg, 0.3);
    }
}
