@import "./../../../styles/sass-resources";

.wrapper {
    height: calc(100% - #{$workspace-bar-height});
    overflow: hidden;
    cursor: grab;
}

.innerWrapper {
    position: relative;
    // background-size: (230px/1.2) (149px/1.2);
    // background-repeat: repeat;
    background-color: #efefef;
    cursor: url("/assets/images/add-word-cursor.svg") 10 10, auto;
}

.giveMore {
    cursor: url("/assets/images/cursor_cloud.png") 10 20, auto;
}

.mouseDown {
    cursor: grabbing !important;
}

.TopBar {
    background: #fff;
    position: absolute;
    top: 0;
    height: $top-bar-height;
    left: 0;
    width: 100%;
}
