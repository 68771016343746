/* ==========================================================================
   DEVELOPMENT
   Description: What are you doing!? You should always work in modules!
                Leave now and never come back!
   ========================================================================== */

body,
text
{
    user-select: none;
}


.dg.main.a
{
    float: left;
}

.sitename
{
    font-size: 20px;

    color: white;
}

.credits
{
    margin-top: 7px;

    color: white;
}

a {
    color: white;
    &:hover, &:visited {
        color: white;
    }
}
