.IconButton {
    cursor: pointer;
    font-size: 22px;

    display: inline-block;
    vertical-align: top;
    text-align: center;
    margin-top: -5px;

    &.active {
        color: #000 !important;
        opacity: 1 !important;

        span {
            color: #000 !important;
            opacity: 1 !important;
        }
    }

    span {
        display: block;
        text-transform: uppercase;
        font-size: 9px;
        margin-left: 0 !important;
        margin-top: -2px;
        line-height: 10px;
    }
}
