.Carousel {
    position: relative;
    overflow: hidden;
    height: 30px;
}

.Previous,
.Next {
    line-height: 30px;
    display: inline-block;
    width: 20px;
    cursor: pointer;
    text-align: center;
    opacity: .6;
    color: #fff;

    i {
        font-size: 24px;
        line-height: 30px;
    }

    &:not(.disabled):hover {
        opacity: 1;
    }

    &.disabled {
        cursor: default;
        opacity: 0;
    }
}

.Items {
    display: inline-block;
    overflow: hidden;

    > div {
        transition: margin-left .1s ease-in;
    }
}

.AddButton {
    font-size: 14px;
    line-height: 10px;
    width: 24px;
    height: 24px;
    margin-top: 2px;
    margin-left: 6px;
    padding: 0;
    color: #fff;
    border: 2px solid #fff;
    background-color: transparent;
    opacity: 0.7;
    &:hover {
        opacity: 1;
    }
}

.Previous,
.Next,
.AddButton,
.Items {
    float: left;
}

.Next {
    margin-left: 3px;
}
