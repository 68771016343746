/* ==========================================================================
   VARIABLES
   ========================================================================== */

/* Bootstrap variables
   -------------------------------------------------------------------------- */
$icon-font-path: '/assets/bower_components/bootstrap-sass/assets/fonts/bootstrap/';

/* Colors Social
   -------------------------------------------------------------------------- */
$color-twitter: #00aced;
$color-linkedin: #007bb6;
$color-facebook: #3b5998;
$color-google: #dd4b39;
$color-youtube: #e52d27;
$color-pinterest: #bd081c;

/* Project variables
   -------------------------------------------------------------------------- */

$workspace-bar-height: 60px;
$top-bar-height: 54px;

$pondr-pink: #f8cdcf;
$pondr-green: #38b097;
$pondr-black: #222;
$pondr-blue: #749bd0;

$text-color: $pondr-black;

$font-family-sans-serif: "Rubik", Helvetica, Arial, sans-serif;
$font-family-base: $font-family-sans-serif;
