.wrapper {
    position: absolute;
    top: 12px;
    right: 15px;

    font-size: 25px;

    > span:last-child {
        border-left: 1px solid #000;
        padding-left: 12px;
        margin-left: 12px;
    }

    > span {
        opacity: 0.7;
        color: #888;
        margin-left: 10px;
        margin-top: -9px;

        &:hover {
            opacity: 1;
        }
    }
}

.wrapperAlternative {
    i {
        color: rgba(#fff, 0.8);

        &:last-child {
            border: 0;
        }

        &:hover {
            color: #fff;
        }
    }
}
