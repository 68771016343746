@import "./../../../styles/sass-resources";

.wrapper {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $pondr-green;
}

.innerWrapper {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: $workspace-bar-height;
    max-height: $workspace-bar-height;
    padding: ($workspace-bar-height - 30) / 2;
    transition: background-color 0.3s;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.LogoWrapper {
    float: left;
    padding: 6px 0;
    padding-left: 14px;

    .logo {
        width: auto;
        height: 18px;
    }
}

.WorkspacesWrapper {
    display: flex;
    // float: left;
    flex: 3;
    justify-content: center;

    .WorkspaceButton {
        font-size: 15px;
        font-weight: 500;
        height: 30px;
        margin-right: 5px;
        margin-left: 5px;
        padding: 2px 14px 3px;
        border: 1px solid #fff;
        outline: none;
        background-color: transparent;

        &:hover {
            background-color: rgba(#fff, 0.2);
        }

        &.isSoftDeleted {
            opacity: 0.5;
        }

        &.isActive {
            background: #fff;
        }

        &:not(.isActive) {
            color: white !important;
        }
    }
}

.disclaimer {
    color: #fff;

    cursor: pointer;

    p {
        margin: 0;
        font-size: 12px;
        text-align: center;
    }
}
