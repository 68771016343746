@import "./../../../styles/sass-resources";

.input {
    input,
    input:focus {
        color: $pondr-green;
        border: 1px solid $pondr-green;
        outline: none;
    }
}

.predictions{
    border-top: none !important;
    border: 1px solid $pondr-green;
}


.addWord {
    input,
    input:focus {
        margin-left: -5px;
        margin-top: -6px;
        padding: 4px;
        font-size: 17px;
    }
}

.addCloud {
    input {
        font-size: 22px;
    }

    input,
    input:focus {
        padding: 2px;
    }
}

@keyframes AppearCloud {
    0% {
        max-width: 0;
    }

    50% {
        max-width: 150px;
    }

    99% {
        max-width: 150px;
    }

    100% {
        max-width: auto;
    }
}

@keyframes AppearWord {
    0% {
        max-width: 0;
    }

    50% {
        max-width: 90px;
    }

    99% {
        max-width: 90px;
    }

    100% {
        max-width: auto;
    }
}

.SuggestionList {
    background: #fff;
    display: block;
    padding: 5px;
    font-size: 14px;

    li {
        list-style: none;
    }
}

.Cloud {
    font-size: 22px;
}
