@import "./../../../styles/sass-resources";

.ModeToggleWrapper {
    position: absolute;
    top: 12px;
    left: 15px;

    &.hidden {
        opacity: 0;
        pointer-events: none;
    }

    :global(.active) {
        opacity: 1;
    }

    span {
        margin-left: 15px;
        transition: all 0.5s ease;
        color: #888;
        opacity: 0.7;

        &:hover {
            opacity: 1;
        }

        &:global(.active) {
            font-size: 22px;
        }

        &:global(.active) > i {
            color: white;
        }

        &.modeName {
            font-family: "Rubik", sans-serif;
            font-size: 11px;
            font-weight: 500;
            text-transform: uppercase;
            position: relative;
            top: -5px;
            opacity: 1;
        }
    }
}

:global(.h-divider) {
    border-left: 1px solid #000;
    opacity: 1;
    display: inline-block;
    height: $top-bar-height - 25;
}
