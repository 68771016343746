@import "./../../../styles/sass-resources";

.wrapper {
    display: flex;

    height: calc(100% - #{$workspace-bar-height});

    background: $pondr-green;

    align-items: center;
    justify-content: center;

    .innerWrapper {
        display: flex;
        flex-direction: column;

        width: 100%;
        height: 100%;

        animation-name: appear;
        animation-duration: 0.5s;
        animation-iteration-count: 1;
        text-align: center;

        justify-content: center;
        align-items: center;
        animation-fill-mode: forwards;

        h1 {
            font-family: "Rubik", sans-serif;
            font-size: 30pt;
            font-weight: 500;

            &:global(.other-font) {
                font-weight: 500;
            }
        }

        h2 {
            margin-top: 0;
        }
    }

    .ponzyImg {
        width: 151px;
        height: auto;
    }

    .searchInput {
        font-family: "Rubik", sans-serif;
        font-size: 18px;

        position: relative;

        display: flex;

        min-width: 300px;
        margin-top: 20px;
        // border-radius: 10px;

        background-color: #fff;

        align-items: center;
        justify-content: space-between;

        .input {
            input {
                line-height: 40px;

                padding: 0 15px;
                min-width: 200px;

                color: #999;
                border: none;
                outline: none;
                background-color: #fff;

                &::placeholder {
                    font-family: "Rubik", sans-serif;
                    font-size: 18px;

                    color: #999;
                }
            }
        }

        .searchIcon {
            line-height: 29px;

            width: 30px;
            height: 30px;
            margin-right: 7px;

            cursor: pointer;
            text-align: center;

            border-radius: 50%;
            background: $pondr-green;

            i {
                line-height: inherit;

                color: #fff;
            }

            &:hover {
                background-color: lighten($pondr-green, 10);
            }
        }
    }
}

.feedback {
    font-family: "Rubik", sans-serif;

    padding-top: 20px;

    h3 {
        font-size: 16pt;
        font-weight: 400;
    }

    p {
        font-size: 13pt;
        font-weight: 400;
        text-transform: uppercase;
    }

    hr {
        border-top-color: #000;
    }

    a {
        font-weight: 500;

        text-decoration: underline;

        color: #000;
    }
}
