/* ==========================================================================
   OVERRIDES
   Description: If you are planning to overrides default styles
   ========================================================================== */

/* Tooltip overrides */

.rc-tooltip-placement-top.rc-tooltip,
.rc-tooltip-placement-bottom.rc-tooltip {
    .rc-tooltip-arrow {
        border-top-color: #fff;
        border-bottom-color: #fff;
    }

    .rc-tooltip-inner {
        font-family: 'Rubik', sans-serif;
        font-size: 12px;
        font-weight: 400;
        padding: 6px;
        color: $text-color;
        background-color: #fff;
        box-shadow: 0 0 18px rgba(0, 0, 0, 0.30);
    }

    &.rc-tooltip-information {
        .rc-tooltip-arrow {
            bottom: 5px;
        }

        .rc-tooltip-inner {
            min-height: auto;
            padding: 3px 8px 2px;
            color: #999;
            border: 1px solid #999;
            border-radius: 0;
        }
    }
}

.rc-tooltip-full-opacity {
    opacity: 1;
    z-index: 1100;

    li {
        border: none !important;
    }
}

.rc-tooltip-extreme-right {
    margin-right: 18px;
    .rc-tooltip-inner {
        border-radius: 0;
    }

    .rc-tooltip-arrow {
    }

    ul {
        font-size: 14px;
        margin: 0;
        padding: 0;

        li {
            padding: 5px;
            list-style: none;
            cursor: pointer;
            color: #666;
            border-bottom: 1px solid #111;

            &:hover {
                color: #000;
            }

            &:last-child {
                border-bottom: 0;
            }
        }
    }
}
